import React from "react";
import classnames from "classnames";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import AuthHeader from "components/Headers/AuthHeader.js";
import { useHistory } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { errorToast, successToast } from "shared/constants";
import SpinnerLoader from "components/Misc/Spinner";
import emailIcon from "../../assets/img/icons/Email.svg"

function CheckEmail({email}) {
  const history = useHistory();

  const [showSpinner, setSpinner] = React.useState(false);

  //handling the submit forgot password
  const handleSubmit = async () => {
    console.log("Handle submit")
  };

  const keyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit();
    }
  };

  return (
    <>
      <SpinnerLoader adminView={true} showSpinner={showSpinner} />
      <AuthHeader />
      <div className="loginForm">
        <Container className="mt-n18 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-default-login border-0 mb-0">
                <CardBody className="px-lg-4 py-lg-3">
                  <div className="d-flex justify-content-start">
                    <img
                      src={emailIcon}
                      className="bh_logo"
                      alt="logo"
                    />
                  </div>
                  <div className="text-muted mt-4 mb-4">
                    <h1 className="black-color">Check your email</h1>
                    <p className="black-color">
                      We've sent a verification email to {email}.
                    </p>
                    <p className="black-color font-weight-bold">
                       <b>Click the link in your email to verify your account.</b> 
                    </p>
                    <p>
                        If you have trouble finding your email, check your spam folder for an email from noreply@example.com
                    </p>
                  </div>
                    <div className="text-center">
                      <Button
                        className="default-button-background mt-2"
                        color="info"
                        type="button"
                        onClick={handleSubmit}
                      >
                        Resend Link
                      </Button>
                    </div>
                    <div className="text-right">
                      <p
                        onClick={(e) => history.push("/auth/login")}
                        className="black-color p-3"
                        role={"button"}
                      >
                        <u>Didn't receive an email?</u>
                      </p>
                    </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Toaster />
    </>
  );
}

export default CheckEmail;
