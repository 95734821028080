import React from "react";
import classnames from "classnames";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import AuthHeader from "components/Headers/AuthHeader.js";
import CheckEmail from  "./CheckEmail.js"
import { resetPassword } from "services/authService";
import { useHistory } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { errorToast, successToast } from "shared/constants";
import SpinnerLoader from "components/Misc/Spinner";
import searchIcon from "../../assets/img/icons/Search.svg"

function ForgotPassword() {
  const history = useHistory();

  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [showSpinner, setSpinner] = React.useState(false);
  const [isRender, setIsRender] = React.useState(false)

  //handling the submit forgot password
  const handleSubmit = async () => {
    setSpinner(true);
    resetPassword(email)
      .then((data) => {
        data.message
          ? setIsRender(true)
          : toast.error("User not Registered", errorToast);
        setSpinner(false);
      })
      .catch(() => {
        setIsRender(true)
        //toast.error("Something went wrong", errorToast);
        setSpinner(false);
      });
  };

  const keyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit();
    }
  };

  return (isRender ? <CheckEmail email={email}/> :
    <>
      <SpinnerLoader adminView={true} showSpinner={showSpinner} />
      <AuthHeader />
      <div className="loginForm">
        <Container className="mt-n18 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-default-login border-0 mb-0">
                <CardBody className="px-lg-6 py-lg-3">
                  <div className="d-flex justify-content-start">
                    <img
                      src={searchIcon}
                      className="bh_logo"
                      alt="logo"
                    />
                  </div>
                  <div className="text-muted mt-4 mb-4">
                    <h1 className="black-color">Forgot your Password?</h1>
                    <p className="black-color">
                      Enter the email associated with your account to change your password.
                    </p>
                  </div>
                  <Form role="form">
                    <FormGroup
                      className={classnames("mb-3", {
                        focused: focusedEmail,
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Email"
                          type="email"
                          onFocus={() => setfocusedEmail(true)}
                          onBlur={() => setfocusedEmail(true)}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          onKeyDown={keyDown}
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="text-center">
                      <Button
                        className="default-button-background mt-2"
                        color="info"
                        type="button"
                        onClick={handleSubmit}
                      >
                        Reset Password
                      </Button>
                    </div>
                    <div className="text-right">
                      <p
                        onClick={(e) => history.push("/auth/login")}
                        className="success-color p-3"
                        role={"button"}
                      >
                        <u>Back to Login</u>
                      </p>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Toaster />
    </>
  );
}

export default ForgotPassword;
